@charset "UTF-8";
/* market.css v1.2.0 date 15.04.23 */

/* Поиск по каталогу */
.market_search_detail{padding-bottom:10px;}
.market_search_scat{padding:10px 5px;}
.market_search_form {
    background-color: #eef0f1;
    border-left: 5px solid #79a206;
    padding: 30px 20px 15px 20px;
}
.market_search_form form{
	position: relative;
}
.market_search_form .search_button > a{
	top:20px;
	right:20px;
}

.market_search_articl{clear:both; margin-bottom:10px;display:none}
.market_search_articl .name{ float:left; width:140px; font-size: 13px; color: #009933; font-weight: bold; padding-top:3px; }
.market_search_articl .text{}
.market_search_articl input {
    width:140px;
	border: thin solid #CCCCCC;
	background-color: #F4FFF8;
    border-radius: 6px;
    font-size: 13px;
    line-height: 14px;
    color: #646464;
    padding:3px 2px;;
}
.market_search_field{
    position: relative;
}

.market_search_descrip{
    margin-bottom: 10px;
}
.market_search_descrip .name{
	display:none;
}
.market_search_category{
    margin-top: 20px;
}
.market_search_calalog,
.market-srv .calalog {
    display: flex;
    margin: 0 -0.8rem;
    flex-wrap: wrap;
}
.market_search_calalog>*,
.market-srv .calalog>* {
    flex-shrink: 0;
    padding: 0 0.4rem;
    margin-top: 1.5rem;
}
@media (min-width: 576px) {
.market_search_calalog>*,
.market-srv .calalog>* {
    padding: 0 0.6rem;
  }
}
.market_search_catalog .text { padding-left:30px;font-size: smaller;margin-right:20px;}
.market_search_scat input[type=checkbox]{ padding-right:30px;}
.market_search_scat ul { list-style: none; margin:0px; padding: 0px; }
.market_search_scat ul ul {margin-left:20px;}
market_search_scat li.search_mcat_name {
    font-weight: 500;
    text-align: left;
    font-size: 20px;
    color: #009933;
    padding: 10px 0px 5px 0px;
    clear: both;
}
.market_search_scat li.search_mcat_form {
    margin: 5px;
    padding: 4px 8px;
    background-color: #F9F9F9;
    white-space: nowrap;
    float: left;
    border-radius: 5px;
    color: #646464;
    font-weight: normal;
}
.market_search_buttom{ clear:both; text-align: center;}
.market_search_buttom input {
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    font-weight: 700;
    margin: 10px 0 0 5px;
    background: #89c74a;
    color: #fff;
}
.market_search_buttom input:hover {
	color: #fff;
	background: #323232;
}
.market_search_rzlt{margin:10px 0;}
.market_search_rzlt.inform{ text-align:center; border: thin solid #cccccc; background-color: #F4FFF8; border-radius:15px; padding:20px 0; width:100%; }
.market_search_rzlt .uppagepanel{position: relative;} /* что бы не загораживались кнопки) */
.market_search_service{font-size:0.8rem; position: relative; padding-top: 10px;} /* что бы не загораживались кнопки) */
.market_search_clear{float:left;}
.market_search_clear a{cursor:pointer;}
.market_search_size{ text-align:right;}
.market_search_size a{cursor:pointer;}
.market_search_size a::after {
    content: "\f3f1";
    font-family: 'Font Awesome 5 Kommek';
    font-size: 1rem;
    padding-left: 5px;
}
.market_search_size .active { position: relative; }
.market_search_size .active::before {
    position: absolute;
    content: '';
    left: -10px;
    border-radius: 50%;
    background-color: #e30c0c;
    width: 8px;
    height: 8px;
}
/* Настройка вывода панели поиска по брендам */
.search-brand {
  width: 100%;
}
.search-brand ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 98%;
}
.search-brand li {margin: 10px 0 0 0;}
.search-brand li {margin: 10px 0 0 0;}

.search-brand a {
  text-decoration: none;
  display: block;  
  font-size: 16px;
  color: rgba(0,0,0,.6);
  padding: 7px 5px 7px 15px;
  position: relative;
  transition: .3s linear;
  overflow: hidden;
    border: solid 1px #CCCCCC;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px rgba(0,0,0,.1);
}
.search-brand a:hover {
  color:#5B5548;
  background: radial-gradient(circle at top, #ffffff, #F4F4F4);
}
.search-brand li#nav_brand0,
.search-category li#nav_cat0 {
  background-color:#89c74a;
  border-radius: 5px;
}
.search-brand li#nav_brand0 a,
.search-category li#nav_cat0 a{
  color:#fff;
  border: none;
}
.search-brand li#nav_brand0 a:hover,
.search-category li#nav_cat0 a:hover {
  color:#fff;
  background:#323232;
}
.search-brand__title{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
  text-transform: uppercase;
  position:relative;
}
.search-brand__title::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: linear-gradient(45deg, #4f4f4f, #ebebeb);
  bottom: -20px;
  left: 0;
}


/*
@media (max-width: 991px) {

  .search-wrap {
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
  }
  .search-wrap .wrap {
    height: calc(100% - 126px);
    overflow: auto;
    
  }
  .search-wrap.show-search {
    top: 40px;
    box-shadow: 0 4px 6px rgba(0,0,0,.2);
  }

  .search-wrap .search-brand,
  .search-wrap .search-category {
    background-color: #fff;
  }
*/

  /* offcanvas search brand and category*/
  .offcanvas__search-wrap a {
    color: #666666;
  }
  .offcanvas__search-wrap .search-brand__title,
  .offcanvas__search-wrap .search-category__title {
    margin: 0;
    text-align: center;
    padding: 20px 0 10px 0;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .offcanvas__search-wrap .search-brand__title::before{
    display: none;
  }
  .offcanvas__search-wrap ul{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-left: -2px;
    width: 100%;
    justify-content: space-between;
  }
  .offcanvas__search-wrap ul>li{
    white-space: nowrap;
    background-color: #f5f5f5;
    margin: 0 0 2px 2px;
    flex-grow: 1;
    border: 1px solid #ebebeb;
  }
  .offcanvas__search-wrap ul>li>a {
    display: block;
    text-align: center;
    padding: 10px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
.offcanvas__search-wrap li#nav_brand0,
.offcanvas__search-wrap li#nav_cat0 {
    flex-grow: 0.1;
}
  
.goods_title_section{
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.goods_text_section {
    margin-top: 0;
    margin-bottom: 1rem;
}
div.goods_marker {font-weight:400; color:#B00006; margin-top:2px;padding-bottom:0px;}
span.goods_marker {color: #B00006;font-weight: bold;}

.product__content {
}
.product__content ul.list__marker--check li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 10px;
}
.product__content ul.list__marker--check li:before {
  content: "\F058";
  position: absolute;
  font-family: "Font Awesome 5 Kommek";
  top: -0.5rem;
  left: 0;
  color: #89c74a;
  font-size: 1.5rem;
}
.product__sticker{
    position: absolute;
    top: 10px;
    right: 0;
}
.product__sticker span{
    display: block;
    padding: 5px 1px;
    color: #fff;
    font: 700 10px/1 sans-serif;
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    text-transform: uppercase;
    text-align: center;
    width: 70px;
}
.sticker--2>span::before {
    content: "Срок годности";
}
.sticker--3>span::before {
    content: "Новинка!";
}
.sticker--4>span::before {
    content: "АКЦИЯ";
}
.product__sticker .sticker--chery {
    margin-bottom: 5px;
}
.product__sticker .sticker--2 {
    background-color: rgb(13 110 253 / 70%);
    border-radius: 4px;
    margin-bottom: 5px;
}
.product__sticker .sticker--3 {
    background-color: rgb(213 69 111 / 70%);
    border-radius: 4px;
    margin-bottom: 5px;
}
.product__sticker .sticker--4 {
    background-color: rgb(3 169 244 / 70%);
    border-radius: 4px;
    margin-bottom: 5px;
}
.sticker--chery.product__sticker--small img {
    width: 50px;
    margin: 0 auto;
}
.sticker--chery.product__sticker--middle img {
    width: 70px;
    margin: 0 auto;
}