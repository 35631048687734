/*
 * pictShow Version 2.1.8 date 12.05.23
*/

/* Блок прокрутки фона */
.showPict-open {
    overflow: hidden;
}

/* Стиль <DIV> картинки */
.class_arbImgShow {
	position:absolute;
	z-index:25;
	text-align: center;
	background-color: #FFF;
	border:solid 2px #ddd;
	padding-top: 30px;
	overflow: hidden;
}

/* Стиль <DIV> со всеми картинками */
.class_arbPictSlider {
	display: inline-block;
	cursor: zoom-out;
}
/* -------------------------------------------------------------------------- */
/*                   Установка блока прокрутки изображений                    */
/* -------------------------------------------------------------------------- */
.class_arbImgShow .class_leftArrow,
.class_arbImgShow .class_rightArrow {
  	border: 1px solid #000;
    border-width: 0 0 0px 0px;
	position: absolute;
	width: 80px;
	align-items: center
}
/* -------------------------------------------------------------------------- */
/*                    Для правого блока и стрелки вправо                      */
/* -------------------------------------------------------------------------- */
.class_arbImgShow .class_rightArrow {
	right: 0;
	top: 0;
	cursor: pointer;
	height: 100%;
	opacity: 0;
	transition: all ease 0.8s;
}
.class_arbImgShow .class_rightArrow:hover {
	opacity: 1;
}
.class_rightArrow .class_rightImage {
	position: absolute;
	top: 50%;
	margin-top: -2em;
	right: 5px;
	padding: 22px;
	display: block;
	background-image: url(/img/shop/right-arrow.svg);
}
/* -------------------------------------------------------------------------- */
/*                    Для левого блока и стрелки влево                        */
/* -------------------------------------------------------------------------- */
.class_arbImgShow .class_leftArrow {
    left: 0px;
	top: 0;
	cursor: pointer;
	height: 100%;
	opacity: 0;
	transition: all ease 0.8s;
}
.class_arbImgShow .class_leftArrow:hover {
	opacity: 1;
}
.class_leftArrow .class_leftImage {
	position: absolute;
	top: 50%;
	margin-top: -2em;
	left: 5px;
	padding: 22px;
	display: block;
	background-image: url(/img/shop/left-arrow.svg);
}

/* -------------------------------------------------------------------------- */
/*                    Для точек с количеством элементов                       */
/* -------------------------------------------------------------------------- */

.class_ScrollGallery {
	position: absolute;
	width: 100%;
	bottom: 50px;
	text-align: center;
}
.class_ScrollGallery .class_arbDot {
	/*cursor: pointer;*/
	height: 12px;
	width: 12px;
	margin: 0 2px;
	background-color: #ddd;
	border-radius: 50%;
	display: inline-block;
	transition: background-color 0.6s ease;
}
.class_ScrollGallery .active_slide {
	background-color: #fff;
	border: 2px solid #1d6f95;
}
/* -------------------------------------------------------------------------- */

/* Стиль <DIV> прелоадера */
.class_arbPrlShow{
	position:absolute;
	z-index: 20;
	background: url('/img_menu/pictshow.gif') no-repeat scroll center center;
}

/* Стиль <DIV> зоны описания картинки */
.class_arbDscShow{
	position:absolute;
	z-index: 30;
	background-image: linear-gradient(top, #4f4f4f, #808080);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#4f4f4f', endColorstr = '#808080');
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr = '#4f4f4f', endColorstr = '#808080')";
	background-image: -moz-linear-gradient(top, #4f4f4f, #808080);
	background-image: -ms-linear-gradient(top, #4f4f4f, #808080);
	background-image: -o-linear-gradient(top, #4f4f4f, #808080);
	background-image: -webkit-gradient(linear, center top, center bottom, from(#4f4f4f), to(#808080));
	background-image: -webkit-linear-gradient(top, #4f4f4f, #808080);
}

/* Стиль <DIV> текста описания картинки */
.class_arbPictDesc{
	font-size: 13px;
	color: #fff;
	font-weight: bold;
	text-align: center;
	padding-top: 2px;
}

/* Стиль <DIV> зоны затенения основного экран */
.class_arbFonShow{
	position:fixed;
	z-index:15;
	top:0;
	left:0;
	width:100vw;
	height:100vh;
	background-color:#000;
	cursor:zoom-out
}

/* Стиль <IMG> картинки */
.class_arbPicture {
	/*border:solid 1px red;*/
	/*padding-top: 20px;*/
	margin: 0px auto;
}